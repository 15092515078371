import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));

const TRACKING_ID = "G-B14L2HJTV4"; // OUR_TRACKING_ID

try {
  ReactGA.initialize(TRACKING_ID);
} catch (error) {
  console.log(error);
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
